import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: url(/img/bgImg.jpg);
        background-size: cover;
        overflow-x: hidden;
    }

    a:hover {
        color: #FFA500;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #E97451 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #fff;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: lightgray;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #E97451;

        :hover {
            color: #E97451;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    video {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        position: fixed;
        z-index: -1;
    }

    .whatsapp_float {
          position: fixed;
          width: 80px;
          height: 80px;
          bottom: 80px;
          right: 80px;
          background-color: #25d366;
          color: #FFF;
          border-radius: 50%;
          text-align: center;
          font-size: 50px;
          box-shadow: 1px 1px 2px #999;
          z-index: 100;
      }

      .whatsapp-icon {
          margin-top: 15px;
      }

      /* for mobile */
      @media screen and (max-width: 767px) {
          .whatsapp-icon {
              margin-top: 10px;
          }

          .whatsapp_float {
              width: 60px;
              height: 60px;
              bottom: 20px;
              right: 10px;
              font-size: 40px;
          }
      }
`;
