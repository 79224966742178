import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { SvgIcon } from '../../components/SvgIcon';
import Container from '../../components/Container';

import i18n from 'i18next';
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from './styles';

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          {/* <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t('Contact')}</Language>
              <Large to="/">{t('Tell us everything')}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:kenji.gohyiqian@gmail.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t('Policy')}</Title>
              <Large to="/" left="true">
                {t('Application Security')}
              </Large>
              <Large left="true" to="/">
                {t('Software Principles')}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/">
                {t('Support Center')}
              </Large>
              <Large left="true" to="/">
                {t('Customer Support')}
              </Large>
            </Col>
          </Row> */}
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t('Address')}</Language>
              <Para>Sunny Singapore</Para>
              <Empty />
              <Language>{t('Phone')}</Language>
              <Para>(+65) 9129 5608</Para>
              <Para>(+65) 8188 3267</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t('Company')}</Title>
              <Para>
                We are highly passionate about creating engaging digital
                experiences that are enjoyable for individuals of all ages!
              </Para>
              {/* <Large left="true" to="/">
                {t('About')}
              </Large>
              <Large left="true" to="/">
                {t('Blog')}
              </Large>
              <Large left="true" to="/">
                {t('Press')}
              </Large> */}
            </Col>
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t('Language')}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange('en')}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange('cn')}>
                  <SvgIcon
                    src="china.png"
                    aria-label="homepage"
                    width="33px"
                    height="33px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> */}
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: '3rem' }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo_v2.png"
                  aria-label="homepage"
                  width="300px"
                  height="90px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://www.linkedin.com/company/nextmediasg/"
                src="linkedin.svg"
              />
            </FooterContainer>
            <Large>©2023 NextMediaSG. All Rights Reserved.</Large>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
